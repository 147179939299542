import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Quicklinks from '../../../components/Quicklinks';
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import Video from '../../../components/Video';

const BernoulliBalance = () => (
  <Layout
    title="Bernoulli Balance - Science at Home"
    className="science-starters has-video"
  >
    {/* Intro Title and Video */}
    <Section className="science-starters__video">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../science-at-home">Science at Home</Link>
          </li>
          <li className="breadcrumb active">Bernoulli Balance</li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">Bernoulli Balance</h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link to="../how-do-we-breathe" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <Video
                guidSrc="b8aaecc7-61ad-44ec-aac9-d19a6023e205"
                className="de-vid"
              ></Video>
              <Link to="../push-and-pull" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Main Content */}
    <Section className="pt-0 pb-6">
      <Container>
        <Row className="mb-4">
          <Column size={8} offset={1}>
            <h2 className="science-at-home__intro">
              A jetliner can weigh over 300 tons, so why doesn’t it just drop
              out of the air? Denise Rutherford explains the Bernoulli principle
              and how without it, planes (and birds) couldn’t fly.
            </h2>
          </Column>
        </Row>
        <Row>
          <Column size={8} offset={1} className="science-home__content">
            {/* Quick Links */}
            <Quicklinks
              className="pl-0"
              title="Quicklinks:"
              smoothScroll
              links={[
                {
                  target: '#background',
                  label: 'Background',
                },
                {
                  target: '#key-concepts',
                  label: 'Key Concepts',
                },
                {
                  target: '#materials',
                  label: 'Materials',
                },
                {
                  target: '#safety',
                  label: 'Safety',
                },
                {
                  target: '#standards',
                  label: 'Standards',
                },
              ]}
            />
            {/* Background */}
            <h3 id="background">Background</h3>
            <p>
              Daniel Bernoulli was a Swiss scientist and mathematician who
              documented important scientific principles in the eighteenth
              century. Particularly interesting was his work in fluid dynamics,
              by which the Bernoulli Principle was discovered. While Bernoulli
              discovered that pressure decreases when the flow speed increases,
              it was actually a man named Leonhard Euler who figured out
              Bernoulli's equation in 1752. Nevertheless, Bernoulli's work
              influenced pivotal technology that has changed our world forever,
              from carburetors in vehicles to airplane wings.
            </p>
            {/* Key Concepts */}
            <h3 id="key-concepts">Key Concepts</h3>
            <ul>
              <li>Air Pressure</li>
              <li>Bernoulli Principle</li>
              <li>Fluid Dynamics</li>
            </ul>
            {/* Materials */}
            <h3 id="materials">Materials</h3>
            <ul>
              <li>An adult helper</li>
              <li>Ping pong ball</li>
              <li>Hair Dryer</li>
              <li>Construction Paper (optional)</li>
              <li>Tape (optional)</li>
            </ul>
            <h3>Preparation</h3>
            <ul>
              <li>
                To use the hair dryer, plug it in with the help of an adult.
              </li>
              <li>
                Make a prediction: What will happen to the ball when you turn on
                the hair dryer and place the ball in the stream of air? What
                about if you tilt the stream of air holding the ball up?
              </li>
              <li>
                Position the hair dryer so that when you turn it on, the air
                that comes out will be moving upwards.{' '}
              </li>
              <li>
                Turn the hair dryer on facing straight up and hold the ball in
                the stream of air. Then, let go of the ball.
              </li>
              <li>
                Try tilting hair dryer so the ball is floating at an angle
                instead of straight up and down. How far over can you tilt the
                hairy dryer before the ball falls out of the stream of air?
              </li>
              <li>
                Remember to clean up when you are done. Put the hairdryer and
                ping pong ball back where you found them.{' '}
              </li>
            </ul>
            <h3>Observation and Results</h3>
            <p>
              You should be able to tilt the hair dryer pretty far before the
              ball actually falls out of the column. This is because the ball is
              actually held in the column by the pressure of the air around it.
              The Bernoulli Principal tells us that moving fluids- like gasses
              or liquids- have lower pressure than fluids that are not moving.
              When you turn the hair dryer on, you are creating a column of
              moving air, which has a lower pressure than the air around it.
              When the ball starts to move out of that column, the high-pressure
              air outside the column pushes it back into the column. This still
              happens even when you tilt the column, so you can tilt pretty far
              before the ball starts to fall, even though it isn’t being pushed
              straight up by the air anymore.{' '}
            </p>
            <p>
              The Bernoulli Principle is also what causes a shower curtain to
              move into the shower when you turn on the water. The moving water
              and air create a low-pressure space, and the high-pressure air
              outside the shower pushes into that low-pressure area. As it
              pushes, it brings the shower curtain with it, so the curtain moves
              toward the inside of the shower.
            </p>
            <p>
              Build a ping pong ball launcher like in the video, using
              construction paper and tape.
            </p>
            <p>
              The Bernoulli Principal works with all fluids- both liquids and
              gasses. To see the Bernoulli Principal work in liquids, try
              floating some cereal like Cheerios about two inches apart in a
              bowl of water, and pour a small stream of water in between them.
              The Cheerios should move toward each other because the moving
              water in creates a low-pressure area.{' '}
            </p>
            <p>
              You can also try tying two balloons to some string, and hanging
              them a few inches apart, and blowing in between. The balloons
              should move towards each other, just like the cheerios.{' '}
            </p>
            {/* Safety */}
            <h3 id="safety">Safety First and Adult Supervision</h3>
            <ul>
              <li>Follow the experiment’s instructions carefully.</li>
              <li>A responsible adult should assist with each experiment.</li>
              <li>
                While science experiments at home are exciting ways to learn
                about science hands-on, please note that some may require
                participants to take extra safety precautions and/or make a
                mess.
              </li>
              <li>
                Adults should handle or assist with potentially harmful
                materials or sharp objects.
              </li>
              <li>
                Adult should review each experiment and determine what the
                appropriate age is for the student’s participation in each
                activity before conducting any experiment.
              </li>
            </ul>
            {/* Standards */}
            <h3 id="standards">
              Next Generation Science Standard (NGSS) Supported - Disciplinary
              Core Ideas
            </h3>
            <p>
              This experiment was selected for Science at Home because it
              teaches NGSS Disciplinary Core Ideas, which have broad importance
              within or across multiple science or engineering disciplines.
            </p>
            <p>
              Learn more about how this experiment is based in NGSS Disciplinary
              Core Ideas.
            </p>
            <p className="bold">
              Engineering Design (ETS)1: Engineering Design
            </p>
            <div className="accordion">
              <div className="accordion__group">
                <div className="accordion__item">
                  <input type="checkbox" id="chck1" aria-label="name"></input>
                  <label className="label" htmlFor="chck1">
                    PS1.A: Structure and Properties of Matter
                  </label>
                  <div className="content">
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>5-PS1-1.</em> Matter of any type can be subdivided
                        into particles that are too small to see, but event then
                        the matter still exists and can be detected by other
                        means.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck2"></input>
                  <label className="label" htmlFor="chck2">
                    PS2.A: Forces and Motion
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>K-PS2-1.</em> Pushes and pulls can have different
                        strengths and directions.
                      </li>
                      <li>
                        <em>K-PS2-2.</em> Pushing or pulling on an object can
                        change the speed or direction os its motion and can
                        start or stop it.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>3-PS2-1.</em> Each force acts on one particular
                        object and has both strength and a direction. An object
                        at rest typically has multiple forces acting on it, but
                        they add to give zero net force on the object. Forces
                        that do not sum to zero can cause changes in the
                        object’s speed or direction of motion.
                      </li>
                      <li>
                        <em>3-PS2-2.</em> The patterns of an object's motion in
                        various situations can be observed and measured; when
                        that past motion exhibits a regular pattern, future
                        motion can be predicted from it.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS2-1.</em> For any pair of interacting objects,
                        the force exerted by the first object on the second
                        object is equal in strength to the force that the second
                        object exerts on the first, but in the opposite
                        direction.
                      </li>
                      <li>
                        <em>MS-PS2-2.</em> The motion of an object is determined
                        by the sum of the forces acting on it; if the total
                        force on the object is not zero its motion will change.
                        The greater the mass of the object, the greater the
                        force needed to achieve the same change in motion. For
                        any given object, a larger force causes a larger change
                        in motion.
                      </li>
                      <li>
                        <em>MS-PS1-6.</em> Some chemical reactions release
                        energy, others store energy.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS2-1.</em> Newton's second law accurately
                        predicts changes in the motion of macroscopic objects.
                      </li>
                      <li>
                        <em>HS-PS2-2.</em> Momentum is defined for a particular
                        frame of reference; it is the ass times the velocity of
                        the object. In any system, total momentum is always
                        conserved.
                      </li>
                      <li>
                        <em>HS-PS1-3.</em> If a system interacts with objects
                        outside itself, the total momentum of the system can
                        change; however, any such change is balanced by changes
                        in the moment of objects outside the system.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck3"></input>
                  <label className="label" htmlFor="chck3">
                    PS2.B: Types of Interactions
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>K-PS2-1.</em> When objects touch or collide, they
                        push on one another and can change motion.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>3-PS2-1.</em> Objects in contact exert forces on
                        each other.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS2-4.</em> Gravitational forces are always
                        attractive. There is a gravitational force between any
                        two masses, but it is very small except when one or both
                        of the objects have a large mass.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck4"></input>
                  <label className="label" htmlFor="chck4">
                    PS3.A: Definition of Energy
                  </label>
                  <div className="content">
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>4-PS3-1.</em> The faster a given object is moving,
                        the more energy it possesses.
                      </li>
                      <li>
                        <em>4-PS3-2.</em> Energy can be moved from place to
                        place by moving objects.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS3-1.</em> Motion energy is properly called
                        kinetic energy.
                      </li>
                      <li>
                        <em>MS-PS3-2.</em> A system of objects may also contain
                        stored (potential) energy, depending on their relative
                        positions.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS3-1.</em> Energy is a quantitative property of
                        a system that depends on the motion and interactions of
                        matter within that system.
                      </li>
                      <li>
                        <em>HS-PS3-2.</em> Within a system, energy is
                        continually transferred from one object to another.
                      </li>
                      <li>
                        <em>HS-PS3-3.</em> At the macroscopic scale, energy
                        manifests itself in multiple ways.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck5"></input>
                  <label className="label" htmlFor="chck5">
                    PS3.B: Conservation of Energy and Energy Transfer
                  </label>
                  <div className="content">
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>4-PS3-2.</em> Energy is present whenever there are
                        moving objects, sound, light, or heat.
                      </li>
                      <li>
                        <em>4-PS3-3.</em> When objects collide, energy can be
                        transferred from one object to another, thereby changing
                        their motion.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS3-5.</em> When the motion energy of an object
                        changes, there is inevitably some other change in energy
                        at the same time.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS3-1.</em> Conservation of energy means that the
                        total change of energy in any system is always equal to
                        the total energy transferred into or out of the system.
                        Energy cannot be created or destroyed.
                      </li>
                      <li>
                        <em>HS-PS3-4.</em> Energy can be transported form one
                        place to another and transferred between systems.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck6"></input>
                  <label className="label" htmlFor="chck6">
                    PS3.C: Relationship Between Energy and Forces
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>K-PS2-1.</em> A bigger push or pull makes things go
                        faster.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>4-PS3-3.</em> When objects collide, the contact
                        forces transfer energy so as to change the objects’
                        motions.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS3-2.</em> When two objects interact, each one
                        exerts a force on the other that can cause energy to be
                        transferred to or from the object.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS3-5.</em> When two objects interacting through
                        a field change relative position, the energy stored in
                        the field is changes.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Column>
          {/* Potentially could still be launching with this
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="career-right-image">
                <Image filename="BernoulliBalance-Profile-Square.jpg" />
              </div>
              <div className="global__profile-content">
                <h3>Denise Rutherford</h3>
                <h4>SVP of Corporate Affairs, 3M</h4>
              </div>
            </div>
          </Column> */}
        </Row>
      </Container>
    </Section>
    {/* Explore Additional Science at Home Videos and Activities */}
    <Section className="science-at-home__cards pb-6">
      <Container>
        <Row>
          <Column size={12}>
            <h2>Explore Additional Science at Home Videos and Activities</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="HowDoWeBreathe-Thumbnail.png" />
              <div className="card__content">
                <h3>How Do We Breathe?</h3>
                <h4>How do our lungs work?</h4>
                <p>
                  Follow along as special guest Dakota Dozier, an offensive
                  lineman for the Minnesota Vikings, makes a model to show how
                  air flows in and out of the lungs with ease.
                </p>
              </div>
              <Link
                to="../how-do-we-breathe"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="PushAndPull-Thumbnail.png" />
              <div className="card__content">
                <h3>Push and Pull</h3>
                <p>
                  Follow along with 3M’s Sam Reiss, as he shows you that
                  magnetism is more than just a simple push and pull – it’s an
                  example of the power of the earth itself.
                </p>
              </div>
              <Link to="../push-and-pull" className="button bg-gradient-purple">
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          {/* Diffusion with Miss America 2020 */}
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="DiffusionWithMiss-Thumbnail.png" />
              <div className="card__content">
                <h3>Diffusion with Miss America 2020</h3>
                <h4>
                  Ever wonder why things mix (or don’t mix) differently in
                  different temperatures of water?
                </h4>
                <p>
                  Join Camille Schrier, a scientist who was crowned Miss America
                  2020, as she explains diffusion and how substances move though
                  water.
                </p>
              </div>
              <Link
                to="../diffusion-with-miss-america-2020"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default BernoulliBalance;
